import $ from 'jquery'

export default class sessionKeepAlive {
  constructor (element) {
    this.$element = $(element)
    this.sessionKeepAliveUrl = this.$element.data('sessionKeepAliveUrl')
    this.sessionKeepAliveType = this.$element.data('sessionKeepAliveType')
    this.sessionKeepAliveInterval = this.$element.data('sessionKeepAliveInterval')

    // initial -> direct!
    this.handleInterval.bind(this)

    window.setInterval(this.handleInterval.bind(this), this.sessionKeepAliveInterval * 1000)
  }

  handleInterval (event) {
    $.post(this.sessionKeepAliveUrl, {
      type: this.sessionKeepAliveType
    }, this.handleKeepAliveRequest.bind(this))
  }

  handleKeepAliveRequest (data, status) {
  }
}
