import $ from 'jquery'

export default class pageAdminTestDetails {
  constructor (element) {
    // console.log(element)
    this.$element = $(element)
    this.$videoVerificationContainer = this.$element.find('.videoVerificationContainer')
    this.$showvideoVerificationContainerButton = this.$element.find('.showvideoVerificationContainerButton')

    this.$showvideoVerificationContainerButton.on('click', this.handleShowVideoVerificationContainerButton.bind(this))

    this.$stripe = this.$element.find('.strip')
    this.$filmStripe = this.$element.find('.filmStrip')
    this.$video = this.$element.find('.video video')

    this.$stripe.on('click', this.handleClickOnFaceDataStripe.bind(this))
  }

  handleShowVideoVerificationContainerButton (event) {
    event.preventDefault()
    this.$showvideoVerificationContainerButton.hide()
    this.$videoVerificationContainer.removeClass('videoVerificationContainerNotVerified')
  }

  handleClickOnFaceDataStripe (event) {
    const clickedX = event.clientX
    const relativeXPos = (clickedX - this.$filmStripe.offset().left) / this.$stripe.width()
    const videoTag = this.$video.get(0)

    videoTag.currentTime = videoTag.duration * relativeXPos
  }
}
