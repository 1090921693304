import $ from 'jquery'
import Swiper from 'swiper'

export default class pageTestGargleTimer {
  constructor (body) {
    this.$body = $(body)
    this.$element = this.$body.find('section.mainContent')

    this.$timerButton = this.$element.find('.timer button')
    this.$timerStepsContainer = this.$element.find('.timerSteps')
    this.$timerProgress = this.$element.find('.timer .progress')
    this.$timerProgressBar = this.$element.find('.timer .progress-bar')
    this.$progessLabelValue = this.$element.find('.timer .progressLabelValue')

    this.startProcessButton = this.$element.find('.startProcess')
    this.endProcessButton = this.$element.find('.endProcess')
    this.nextUrl = this.endProcessButton.data('nextUrl')

    this.explainPrepare = this.$element.find('.explainText.prepare')
    this.explainOnTimer = this.$element.find('.explainText.onTimer')

    this.$timerButton.on('click', this.handleStartTimer.bind(this))
    this.startProcessButton.on('click', this.handleStartProcess.bind(this))
    this.endProcessButton.on('click', this.handleEndProcess.bind(this))

    this.interval = null
    this.startTime = null
    this.timerLengthSeconds = parseInt(this.$timerProgress.data('timerLength'), 10)

    this.swiper = new Swiper(this.$timerStepsContainer.get(0), {
      // allowSlideNext: false,
      allowSlidePrev: false,
      allowTouchMove: false
    })
    this.initialize()
  }

  activateStep (stepIdx) {
    this.swiper.slideTo(stepIdx - 1)
  }

  initialize () {
    this.activateStep(1)
    this.startProcessButton.prop('disabled', false)
  }

  handleStartTimer (event) {
    event.preventDefault()
    this.$timerButton.hide()
    this.$timerProgress.show()
    this.explainPrepare.hide()
    this.explainOnTimer.show()
    this.startTime = new Date()
    this.interval = setInterval(this.handleInterval.bind(this), 250)
  }

  handleStartProcess (event) {
    event.preventDefault()
    this.activateStep(2)
  }

  handleEndProcess () {
    this.activateStep(4)
  }

  handleInterval (event) {
    const currentTime = new Date()
    const seconds = Math.round((currentTime.getTime() - this.startTime.getTime()) / 1000)

    if (seconds > this.timerLengthSeconds) {
      clearInterval(this.interval)
      this.interval = null
      this.finalStep()
      return
    }

    const remainingTime = this.timerLengthSeconds - seconds
    const percent = 100 - (100 / this.timerLengthSeconds * remainingTime)
    this.$progessLabelValue.text(`${remainingTime}`)
    this.$timerProgressBar.width(`${percent}%`)
  }

  finalStep () {
    this.activateStep(3)
  }
}
