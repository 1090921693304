import $ from 'jquery'
import Cleave from 'cleave.js'
import Behaviors from '../behaviors'

export default class formCleaveDateInput {
  constructor (element) {
    this.$element = $(element)
    this.$input = $(element).find('input')
    this.$form = this.$input.closest('form')
    this.fieldName = this.$input.attr('name')
    this.invalidDateMessage = this.$element.data('invalidDateMessage')

    this.codeCleave = new Cleave(this.$input.get(0), {
      date: true,
      delimiter: '.',
      datePattern: ['d', 'm', 'Y']
    })

    this.registerField()
  }

  serialize () {
    const formattedDate = this.$input.val()
    const dateParts = formattedDate.split('.')
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  }

  validate () {
    const formattedDate = this.$input.val()
    const dateParts = formattedDate.split('.')

    const isValidDate = dateParts.length === 3
    if (!isValidDate || formattedDate.length !== 10) {
      return this.invalidDateMessage
    }

    return null
  }

  registerField () {
    const formBehavior = Behaviors.getBehaviorFromElement(this.$form.get(0))

    // if not available - try again
    if (!formBehavior) {
      window.setTimeout(() => {
        this.registerFrontendValidator()
      }, 200)
      return
    }

    formBehavior.behavior.registerValidator(this.fieldName, this.validate.bind(this))
    formBehavior.behavior.registerSerializer(this.fieldName, this.serialize.bind(this))
  }
}
