import $ from 'jquery'
import FormField from '../formField'
import Modal from 'bootstrap/js/src/modal'
import SmoothScrollTo from 'flamingo-carotene-smooth-scroll-to'

export default class formSubmit {
  constructor (element) {
    this.$element = $(element)
    this.$form = this.$element
    this.$buttons = this.$form.find('button')
    this.$generalFormError = this.$element.find('.generalFormError')

    this.formFields = {}
    this.initializeFields()

    this.customValidators = []
    this.customSerializer = []
    this.customResponseHandler = []

    this.formId = this.$form.attr('id')

    this.$loadingSpinner = this.$form.find('.loadingSpinner')

    this.url = this.$form.attr('action')
    this.method = this.$form.attr('method')

    this.$buttons.on('click', this.handleButtonClick.bind(this))
    this.$form.on('submit', this.handleSubmit.bind(this))
  }

  initializeFields () {
    this.$form.find('[name]').each((index, item) => {
      const $field = $(item)
      const name = $field.attr('name')
      this.formFields[name] = new FormField($field)
    })
  }

  handleButtonClick (event) {
    const $button = $(event.target)
    if ($button.attr('type') === 'submit') {
      const buttonName = $button.attr('name')
      const buttonValue = $button.attr('value')
      if (buttonName && buttonValue) {
        this.$form.append(`<input type="hidden" name="${buttonName}" value="${buttonValue}">`)
      }
    }
  }

  handleSubmit (event) {
    event.preventDefault()

    if (!this.validateFrontend()) {
      this.scrollToFirstError()
      return
    }

    this.$generalFormError.text('')

    const ajaxSettings = {
      url: this.url,
      method: this.method,
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(this.getFormData()),
      headers: {}
    }

    this.$form.addClass('submitting')

    $.ajax(ajaxSettings)
      .done(this.handleRequestDone.bind(this))
      .fail(this.handleRequestFail.bind(this))
  }

  handleRequestDone (data, textStatus, jqXHR) {
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleRequestFail (jqXHR, textStatus, errorThrown) {
    let data = {}
    try {
      if (jqXHR.responseText) {
        data = JSON.parse(jqXHR.responseText)
      }
    } catch (e) {
      console.error(jqXHR.responseText)
    }
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  resetValidation () {
    const fields = this.getFormData()
    for (const fieldName in fields) {
      if (this.formFields.hasOwnProperty(fieldName)) {
        this.formFields[fieldName].resetValidation()
      }
    }
  }

  handleResponse (status, responseData, rawData) {
    // failed
    if (status < 400) {
      if (responseData.hasOwnProperty('showModal')) {
        const $modal = this.$form.find(`#${this.formId}FormModal`)
        const $modalTitle = $modal.find('.modal-title')
        const $modalBody = $modal.find('.modal-body')
        const $modalButton = $modal.find('.modal-footer button')

        $modalTitle.html(responseData.showModal.title)
        $modalBody.html(responseData.showModal.body)
        $modalButton.text(responseData.showModal.buttonLabel)

        const modal = new Modal($modal.get(0), {})
        modal.show()
        this.$form.removeClass('submitting')
        return
      }

      if (responseData.hasOwnProperty('redirect')) {
        this.callCustomResponseHandler(true, 'redirect', { status: status, responseData: responseData, rawData: rawData })
        window.location.href = responseData.redirect
        return
      }

      if (responseData.hasOwnProperty('reload')) {
        this.callCustomResponseHandler(true, 'reload', { status: status, responseData: responseData, rawData: rawData })
        window.location.reload()
        return
      }

      const $modal = this.$form.find(`#${this.formId}FormModal`)
      const $modalBody = $modal.find('.modal-body')
      let errorContent = `<h3>Error</h3>`
      errorContent += `<p>Form Ajax response received, but no action is given.</p>`
      errorContent += `<p>You need to use "setRedirect" or "setReload" in the FormSubmitResultService.</p>`
      errorContent += rawData
      $modalBody.html(errorContent)

      const modal = new Modal($modal.get(0), {})

      modal.show()
      this.$form.removeClass('submitting')
      this.callCustomResponseHandler(false, 'fail', { status: status, responseData: responseData, rawData: rawData, errorContent: errorContent })
      return
    }

    let hasError = false
    if (responseData.hasOwnProperty('generalFormError')) {
      this.handleGeneralFormError(responseData.generalFormError)
      hasError = true
    }
    if (responseData.hasOwnProperty('fieldErrors')) {
      this.handleFieldErrors(responseData.fieldErrors)
      hasError = true
    }
    if (responseData.hasOwnProperty('suggestedFieldValues')) {
      this.handleSuggestedFieldValues(responseData.suggestedFieldValues)
      hasError = true
    }

    if (responseData.hasOwnProperty('error')) {
      hasError = true
      const $modal = this.$form.find(`#${this.formId}FormModal`)
      const $modalTitle = $modal.find('.modal-title')
      const $modalBody = $modal.find('.modal-body')

      let errorContent = responseData.error.message
      let errorHeadline = 'Form Error'
      if (status === 503) {
        const errorParts = errorContent.split('\n\n')
        if (errorParts.length > 1) {
          errorHeadline = errorParts.shift()
          errorContent = errorParts.join('\n\n')
        }
      }

      errorContent = `<p>${errorContent.split('\n\n').join(`</p><p>`)}</p>`
      errorContent = errorContent.split('\n---\n').join(`<hr>`)
      errorContent = errorContent.split('\n').join(`<br>`)
      if (responseData.error.hasOwnProperty('stacktrace')) {
        errorContent += `<p>${responseData.error.stacktrace.join(`<br>`)}</p>`
      }

      $modalTitle.html(errorHeadline)
      $modalBody.html(errorContent)

      const modal = new Modal($modal.get(0), {})
      modal.show()
    }

    this.callCustomResponseHandler(false, hasError ? 'error' : 'success', { status: status, responseData: responseData, rawData: rawData })

    this.$form.removeClass('submitting')
    this.scrollToFirstError()
  }

  handleGeneralFormError (formError) {
    if (formError) {
      this.$generalFormError.text(formError)
      this.$generalFormError.show()
    } else {
      this.$generalFormError.hide()
    }
  }

  handleFieldErrors (fieldErrors) {
    for (const fieldName in fieldErrors) {
      const message = fieldErrors[fieldName]
      if (this.formFields.hasOwnProperty(fieldName)) {
        this.formFields[fieldName].error(message)
      }
    }
  }

  handleSuggestedFieldValues (suggestedFieldValues) {
    for (const fieldName in suggestedFieldValues) {
      const value = suggestedFieldValues[fieldName]
      if (this.formFields.hasOwnProperty(fieldName)) {
        this.formFields[fieldName].setValue(value)
      }
    }
  }

  scrollToFirstError () {
    const visibleErrorNode = this.$form.find('.is-invalid').closest('.formRow').get(0)
    if (visibleErrorNode) {
      const headerHeight = $('header').outerHeight()
      new SmoothScrollTo()
        .setOffset(headerHeight)
        .scrollTo(visibleErrorNode)
    }
  }

  getFormData () {
    const indexedArray = {}
    $.map(this.$form.serializeArray(), function (n, i) {
      indexedArray[n['name']] = n['value']
    })
    for (const serializer of this.customSerializer) {
      indexedArray[serializer.fieldName] = serializer.serialize()
    }

    return indexedArray
  }

  registerSerializer (fieldName, serializer) {
    this.customSerializer.push({ fieldName: fieldName, serialize: serializer })
  }

  registerValidator (fieldName, validator) {
    this.customValidators.push({ fieldName: fieldName, validate: validator })
  }

  registerResponseHandler (callable) {
    this.customResponseHandler.push(callable)
  }

  callCustomResponseHandler (successFull, responseType, rawData) {
    for (const handler of this.customResponseHandler) {
      handler(successFull, responseType, rawData)
    }
  }

  validateFrontend () {
    let formIsValid = true
    for (const validator of this.customValidators) {
      const error = validator.validate()
      if (error) {
        formIsValid = false
        this.formFields[validator.fieldName].error(error)
      }
    }

    return formIsValid
  }
}
