import $ from 'jquery'

export default class filmStrip {
  constructor (element) {
    this.$element = $(element)
    this.mouseX = 0
    this.stripSize = 0
    this.containerSize = 0
    this.$element.on('mousemove', this.handleMouseMove.bind(this))
  }

  handleMouseMove (event) {
    const parentOffset = this.$element.offset()
    this.mouseX = event.pageX - parentOffset.left
    this.containerSize = this.$element.width()
    this.stripSize = this.$element.get(0).scrollWidth
    window.requestAnimationFrame(this.redraw.bind(this))
  }

  redraw (event) {
    const mousePositionPercentage = 100 / this.containerSize * this.mouseX
    const scrollMaxX = this.stripSize - this.containerSize
    const scrollLeft = mousePositionPercentage / 100 * scrollMaxX
    this.$element.scrollLeft(scrollLeft)
  }
}
