
import $ from 'jquery'

export default class scrollHint {
  constructor (element) {
    this.$element = $(element)
    this.$window = $(window)
    window.setTimeout(this.initialize.bind(this), 2000)
  }

  initialize () {
    this.$window.on('scroll', this.handleScroll.bind(this))
    this.handleScroll()
  }

  handleScroll (event) {
    const scrollTop = this.$window.scrollTop()
    if (scrollTop > 100) {
      window.requestAnimationFrame(this.hideHint.bind(this))
    }
    if (scrollTop < 10) {
      window.requestAnimationFrame(this.showHint.bind(this))
    }
  }

  showHint () {
    if (this.isScollable()) {
      this.$element.addClass('active')
    }
  }

  hideHint () {
    this.$element.removeClass('active')
  }

  isScollable () {
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    return scrollHeight > clientHeight + 100
  }
}
