/* global history */
import $ from 'jquery'
import './bootstrap'

import Tracking from './tracking'
import Behaviors from './behaviors'

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

$(document).ready(function () {
  Tracking.initialize()
  Behaviors.attachBehaviors(document)
  $('body').append('<div id="homeTestPageInitialized"></div>')
})
