import $ from 'jquery'
import Behaviors from './../behaviors'
import validateDate from 'validate-date'

export default class formDateInput {
  constructor (element) {
    this.$element = $(element)

    this.fieldName = this.$element.data('fieldName')
    this.invalidDateMessage = this.$element.data('invalidDateMessage')
    this.$form = this.$element.closest('form')
    this.$formDateContainer = this.$element.find('.formDateContainer')

    this.$currentFocusField = null

    this.$formDayField = this.$element.find('input.formDateDay')
    this.$formMonthField = this.$element.find('input.formDateMonth')
    this.$formYearField = this.$element.find('input.formDateYear')

    this.$fields = this.$formDayField.add(this.$formMonthField).add(this.$formYearField)

    this.$fields.on('blur', this.handleFieldBlur.bind(this))
    this.$fields.on('focus', this.handleFieldFocus.bind(this))
    this.$fields.on('keydown', this.handleFieldKeyDown.bind(this))

    this.registerField()
  }

  ignoreKeyEvent (keyCode) {
    // see http://gcctech.org/csc/javascript/javascript_keycodes.htm
    if ((keyCode >= 33 && keyCode <= 47) ||
      (keyCode >= 112 && keyCode <= 145) ||
      (keyCode >= 91 && keyCode <= 93) ||
      (keyCode >= 144 && keyCode <= 145) ||
      (keyCode >= 0 && keyCode <= 31)) {
      return true
    }
    return false
  }

  handleFieldKeyDown (event) {
    if (this.ignoreKeyEvent(event.keyCode)) {
      return
    }

    const $field = $(event.target)
    if (this.$currentFocusField.get(0) !== $field.get(0)) {
      return
    }

    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const allowedSeparator = ['.', ',', ';', ':', '/']

    if (allowedKeys.includes(event.key)) {
      return
    }

    event.preventDefault()
    if (allowedSeparator.includes(event.key)) {
      if ($field.val() !== '') {
        this.jumpToNextField($field)
      }
    }
  }

  jumpToNextField ($currentField) {
    let $nextfield = null
    if ($currentField.get(0) === this.$formDayField.get(0)) {
      $nextfield = this.$formMonthField
    } else if ($currentField.get(0) === this.$formMonthField.get(0)) {
      $nextfield = this.$formYearField
    }
    if ($nextfield) {
      window.setTimeout(() => {
        $nextfield.focus()
        window.setTimeout(() => {
          $nextfield.get(0).selectionStart = 0
          $nextfield.get(0).selectionEnd = 10000
        }, 1)
      }, 50)
    }
  }

  handleFieldBlur (event) {
    this.$currentFocusField = null
    this.$formDateContainer.removeClass('focus')
  }

  handleFieldFocus (event) {
    this.$currentFocusField = $(event.target)
    this.$formDateContainer.addClass('focus')
  }

  serialize () {
    return `${this.$formYearField.val()}-${this.$formMonthField.val()}-${this.$formDayField.val()}`
  }

  validate () {
    const isValidDate = validateDate(`${this.$formDayField.val()}/${this.$formMonthField.val()}/${this.$formYearField.val()}`, 'boolean', 'dd/mm/yyyy')
    if (!isValidDate) {
      return this.invalidDateMessage
    }
    // return error, if this is NOT a date
    return null
  }

  registerField () {
    const formBehavior = Behaviors.getBehaviorFromElement(this.$form.get(0))

    // if not available - try again
    if (!formBehavior) {
      window.setTimeout(() => {
        this.registerFrontendValidator()
      }, 200)
      return
    }

    formBehavior.behavior.registerValidator(this.fieldName, this.validate.bind(this))
    formBehavior.behavior.registerSerializer(this.fieldName, this.serialize.bind(this))
  }
}
