import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'
import Behaviors from '../behaviors'

export default class openFormModal {
  constructor (element) {
    this.$modal = $(element)

    this.$form = this.$modal.find('form')
    this.waitForFormSubmit()

    this.$buttons = this.$modal.find('.modal-footer [type=button]')
    this.$buttons.on('click.openFormModal', this.handleButtonClick.bind(this))

    this.$modal.on('show.bs.modal', this.handleModalShow.bind(this))
    this.$modal.on('hide.bs.modal', this.handleModalHide.bind(this))
    this.modal = new Modal(this.$modal.get(0), {})
  }

  handleModalShow (event) {
    const $button = $(event.relatedTarget)
    const prefillData = $button.data('modalFormPrefill')

    this.formSubmit.resetValidation()
    if (prefillData) {
      for (const fieldName in prefillData) {
        this.formSubmit.formFields[fieldName].setValue(prefillData[fieldName])
      }
    }
  }

  handleModalHide () {

  }

  handleButtonClick (event) {
    const $button = $(event.target) // Button that triggered the modal
    const action = $button.data('action') // Extract info from data-* attributes
    if (action === 'submit') {
      this.$form.trigger('submit')
    } else {
      this.modal.hide()
    }
  }

  handleResponse (successFull, responseType, rawData) {
    if (successFull) {
      this.modal.hide()
    }
  }

  waitForFormSubmit () {
    const formBehavior = Behaviors.getBehaviorFromElement(this.$form.get(0))

    // if not available - try again
    if (!formBehavior) {
      window.setTimeout(() => {
        this.waitForFormSubmit()
      }, 200)
      return
    }

    this.formSubmit = formBehavior.behavior
    this.formSubmit.registerResponseHandler(this.handleResponse.bind(this))
  }
}
