import $ from 'jquery'
import Behaviors from './behaviors'
import SmoothScrollTo from 'flamingo-carotene-smooth-scroll-to'

export default class pageVerification {
  constructor (body) {
    this.$body = $(body)
    this.$element = this.$body.find('section.mainContent')
    this.$contentHeadline = this.$element.find('.stage > h2:first-child')
    this.$photo = this.$element.find('.photoField')
    this.$scanButton = this.$body.find('.scanPhoto')
    this.$scanButton.on('click', this.handleScanButton.bind(this))
    this.findPhoto()

    this.mobileScroll()
  }

  mobileScroll () {
    const windowWidth = window.innerWidth
    if (windowWidth > 520) {
      return
    }
    const headlineDomEle = this.$contentHeadline.get(0)
    const headlineMarginH = this.$contentHeadline.outerHeight(true) - this.$contentHeadline.outerHeight()
    const offset = this.$body.find('.headerGradientLine').height() + headlineMarginH
    new SmoothScrollTo()
      .setDuration(0)
      .setOffset(offset)
      .scrollTo(headlineDomEle)
  }

  findPhoto () {
    const behavior = Behaviors.getBehaviorFromElement(this.$photo.get(0))

    // if not available - try again
    if (!behavior) {
      window.setTimeout(() => {
        this.findPhoto()
      }, 200)
      return
    }

    this.photoBehavior = behavior.behavior
    this.waitForCam()
  }

  waitForCam () {
    // console.log('waitForCam')
    if (!this.photoBehavior.cameraPhoto) {
      window.setTimeout(() => {
        this.waitForCam()
      }, 200)
      return
    }

    this.initialize()
  }

  handleScanButton (event) {
    event.preventDefault()
    this.photoBehavior.handleCapturePhoto(null)
  }

  initialize () {
    // console.log('initialize')
    this.$scanButton.removeAttr('disabled')
  }
}
