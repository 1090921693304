import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import $ from 'jquery'

export default class photo {
  constructor (element) {
    this.$element = $(element)
    this.$video = this.$element.find('video')
    this.$switchCam = this.$element.find('.switchCam')
    this.$capturePhoto = this.$element.find('.capturePhoto')
    this.$video = this.$element.find('video')
    this.$input = this.$element.find('input')
    this.$form = this.$input.closest('form')
    this.cameraPhoto = null
    this.autoSubmitOnCapture = this.$element.data('autoSubmitOnCapture')
    this.$switchCam.on('click', this.handleSwitchCam.bind(this))
    this.$capturePhoto.on('click', this.handleCapturePhoto.bind(this))

    this.allCams = []
    this.currentCamIdx = -1

    this.init()
  }

  init () {
    // console.log(this.$video.get(0))
    this.cameraPhoto = new CameraPhoto(this.$video.get(0))
    this.startCamera(FACING_MODES.ENVIRONMENT)
  }

  startCamera (facing) {
    this.cameraPhoto.startCameraMaxResolution(facing)
      .then(this.handleStreamStart.bind(this))
      .catch(this.handleStreamError.bind(this))
  }

  handleStreamStart (stream) {
    // console.log(stream)
  }

  handleStreamError (err) {
    console.error(err)
  }

  getAllCams () {
    const cams = []
    this.cameraPhoto.enumerateCameras()
      .then((cameras) => {
        cameras.forEach((camera) => {
          cams.push(camera.deviceId)
        })
      })
    return cams
  }

  handleSwitchCam (event) {
    event.preventDefault()

    if (this.allCams.length < 1) {
      this.allCams = this.getAllCams()
    }
    this.currentCamIdx++
    if (this.currentCamIdx > this.allCams.length - 1) {
      this.currentCamIdx = 0
    }

    // console.log(this.allCams[this.currentCamIdx])
    this.startCamera(this.allCams[this.currentCamIdx])
  }

  handleCapturePhoto (event) {
    if (event) {
      event.preventDefault()
    }

    const dataConfig = {
      size: 1,
      imageType: IMAGE_TYPES.JPG,
      imageCompression: 0.8
    }
    const dataUri = this.cameraPhoto.getDataUri(dataConfig)
    this.$input.val(dataUri)
    if (this.autoSubmitOnCapture) {
      this.$form.trigger('submit')
    }
  }
}
