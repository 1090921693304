import $ from 'jquery'
import checkPasswordStrength from 'check-password-strength'

export default class passwordStrengthUi {
  constructor (element) {
    this.$formRow = $(element)
    this.$field = this.$formRow.find('input')
    this.lables = this.$formRow.data('passwordStrength')
    this.drawUI()

    this.options = [
      {
        id: 0,
        barSize: 25,
        className: 'strengthWeak',
        value: this.lables[0],
        minDiversity: 0,
        minLength: 0
      },
      {
        id: 1,
        barSize: 40,
        className: 'strengthMedium',
        value: this.lables[1],
        minDiversity: 4,
        minLength: 8
      },
      {
        id: 2,
        barSize: 75,
        className: 'strengthStrong',
        value: this.lables[2],
        minDiversity: 4,
        minLength: 10
      },
      {
        id: 3,
        barSize: 100,
        className: 'strengthStrong',
        value: this.lables[3],
        minDiversity: 4,
        minLength: 12
      }
    ]

    this.$field.on('keyup change', this.handleFieldChange.bind(this))
    this.handleFieldChange()

    // this.setPasswordStrength(0)
  }

  handleFieldChange (event) {
    const fieldValue = this.$field.val()
    console.log('handleFieldChange', this.$field)
    if (fieldValue === '') {
      this.setPasswordStrength(-1)
    } else {
      const score = checkPasswordStrength.passwordStrength(fieldValue)
      console.log(score)
      this.setPasswordStrength(score.id)
    }
  }

  setPasswordStrength (id) {
    if (id === -1) {
      this.setProgressBarClass('disabled')
      return
    }

    let barSize = 0
    let className = ''
    let label = ''

    for (const option of this.options) {
      if (option.id === id) {
        barSize = option.barSize
        className = option.className
        label = option.value
      }
    }
    this.setProgressBarValue(barSize)
    this.setProgressBarClass(className)
    this.setProgressBarText(label)
  }

  setProgressBarValue (value) {
    this.$progressBar.css('width', `${value}%`)
  }

  setProgressBarClass (className) {
    const allClasses = ['disabled']
    for (const option of this.options) {
      allClasses.push(option.className)
    }

    this.$progressContainer.removeClass(allClasses)
    this.$progressContainer.addClass(className)
  }

  setProgressBarText (text) {
    this.$progressLabel.text(text)
  }

  drawUI () {
    this.$progressContainer = $(`<div class="passwordStrength"></div>`)

    this.$progressBar = $(`<div class="progress-bar" role="progressbar" aria-label="Basic example"aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>`)
    this.$progress = $(`<div class="progress"></div>`)
    this.$progress.append(this.$progressBar)
    this.$progressContainer.append(this.$progress)

    this.$progressLabel = $(`<div class="progressLabel"></div>`)
    this.$progressContainer.append(this.$progressLabel)

    $(this.$formRow).append(this.$progressContainer)
  }
}
