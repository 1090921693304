/* global MediaRecorder, FileReader */

import $ from 'jquery'

export default class VideoTake {
  constructor (stream, takeId, recordUrl, faceDetectionResultCallback) {
    this.stream = stream
    this.takeId = takeId
    this.recordUrl = recordUrl
    this.chunkSize = 1000 // in milliseconds
    this.videoBitsPerSecond = 1000000
    this.faceDetectionResultCallback = faceDetectionResultCallback
    this.chunks = []

    this.dataSize = 0
    this.postedDataSize = 0
    this.postedChunks = 0

    this.init()
  }

  getStats () {
    return {
      takeId: this.takeId,
      dataSize: this.dataSize,
      postedDataSize: this.postedDataSize,
      postedChunks: this.postedChunks,
      duration: this.chunkSize * this.postedChunks
    }
  }

  getStream () {
    return this.stream
  }

  isRecording () {
    return this.mediaRecorder.state === 'recording'
  }

  init () {
    // let containerType = 'video/webm'
    const options = {}
    // options.videoBitsPerSecond = 1500000
    if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      options.mimeType = 'video/webm;codecs=h264'
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      options.mimeType = 'video/webm'
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      // Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
      // containerType = 'video/mp4'
      options.mimeType = 'video/mp4'
      // options.videoBitsPerSecond = 2500000
    }

    options.videoBitsPerSecond = this.videoBitsPerSecond
    options.audioBitsPerSecond = 5000

    this.usedCodec = options.mimeType

    this.mediaRecorder = new MediaRecorder(this.stream, options)
    this.mediaRecorder.ondataavailable = this.handleMediaRecorderDataAvailable.bind(this)
    this.mediaRecorder.onstop = this.handleMediaRecorderStop.bind(this)
  }

  startRecord () {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== 'recording') {
        this.mediaRecorder.start(this.chunkSize) // milliseconds, so 1 second is 1000
      }
    }
  }

  stopRecording () {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop()
      }
    }
  }

  handleMediaRecorderStop (event) {
    this.chunks = []
  }

  handleMediaRecorderDataAvailable (event) {
    this.chunks.push(event.data)
    const fr = new FileReader()
    fr.onload = this.handleMediaRecorderChunk.bind(this)
    fr.readAsDataURL(event.data)
  }

  handleMediaRecorderChunk (progressEvent) {
    const base64Prefix = 'base64,'
    const result = progressEvent.target.result
    const chunk = result.substring(result.search(base64Prefix) + base64Prefix.length)
    const faceData = this.faceDetectionResultCallback()

    const postUrl = this.recordUrl.replace('--takeId--', this.takeId)
    $.ajax({
      type: 'POST',
      beforeSend: function (request) {
        request.setRequestHeader('faceData', JSON.stringify(faceData))
      },
      url: postUrl,
      dataType: 'json',
      data: chunk,
      success: function (data) {
        // console.log(data + ' yes')
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        // console.log('Status: ' + textStatus + '   ' + ' Error: ' + errorThrown)
      }
    })

    this.dataSize += atob(chunk).length
    this.postedDataSize += chunk.length
    this.postedChunks++
  }
}
