import $ from 'jquery'
import Behaviors from '../../behaviors'
import Swiper from 'swiper'

export default class pageTestGargleVideo {
  constructor (body) {
    this.$body = $(body)
    this.$element = this.$body.find('section.mainContent')
    this.$allSteps = this.$element.find('.videoStep')

    this.$recordingIndicator = this.$element.find('.recordingIndicator')
    this.$stepsContainer = this.$element.find('.videoSteps')
    this.$toolbar = this.$element.find('.videoToolbar')
    this.$timerButton = this.$element.find('.timer button')
    this.$timerProgress = this.$element.find('.timer .progress')
    this.$timerProgressBar = this.$element.find('.timer .progress-bar')
    this.$progessLabel = this.$element.find('.timer .progessLabel')
    this.$progessLabelValue = this.$element.find('.timer .progressLabelValue')

    this.startProcessButton = this.$element.find('.startProcess')
    this.endProcessButton = this.$element.find('.endProcess')
    this.nextUrl = this.endProcessButton.data('nextUrl')

    this.$noFaceAlert = this.$element.find('.face-not-detected')
    this.$faceDetectedIcon = $('.faceDetectionStatus')

    this.explainPrepare = this.$element.find('.explainText.prepare')
    this.explainOnTimer = this.$element.find('.explainText.onTimer')

    this.$timerButton.on('click', this.handleStartTimer.bind(this))
    this.startProcessButton.on('click', this.handleStartProcess.bind(this))
    this.endProcessButton.on('click', this.handleEndProcess.bind(this))
    this.interval = null
    this.startTime = null

    this.$video = this.$element.find('#gargleTestVideo')
    this.videoBehavior = null
    this.timerLengthSeconds = parseInt(this.$timerProgress.data('timerLength'), 10)

    this.swiper = new Swiper(this.$stepsContainer.get(0), {
      // allowSlideNext: false,
      allowSlidePrev: false,
      allowTouchMove: false
    })
    this.findVideo()
  }

  findVideo () {
    const behavior = Behaviors.getBehaviorFromElement(this.$video.get(0))
    // if not available - try again
    if (!behavior) {
      window.setTimeout(() => {
        this.findVideo()
      }, 200)
      return
    }

    this.videoBehavior = behavior.behavior
    this.videoBehavior.setFaceDetect = this.setFaceDetect.bind(this)

    this.waitForVideo()
  }

  setFaceDetect () {
    let faceDeteced = false
    if (this.videoBehavior.currentFaceData && this.videoBehavior.currentFaceData.facePresent) {
      faceDeteced = true
    }

    if (this.$faceDetectedIcon.length > 0) {
      if (faceDeteced) {
        this.$faceDetectedIcon.addClass('detected')
        this.$noFaceAlert.removeClass('not-detected')
      } else {
        this.$faceDetectedIcon.removeClass('detected')
        this.$noFaceAlert.addClass('not-detected')
      }
    }
  }

  waitForVideo () {
    // console.log('waitForVideo')
    if (!this.videoBehavior.faceApiInitialized) {
      window.setTimeout(() => {
        this.waitForVideo()
      }, 200)
      return
    }

    this.initialize()
  }

  activateStep (stepIdx) {
    this.swiper.slideTo(stepIdx - 1)
  }

  initialize () {
    this.activateStep(2)
    this.startProcessButton.prop('disabled', false)
    this.explainPrepare.show()
    this.explainOnTimer.hide()
    this.$toolbar.addClass('active')
  }

  handleStartTimer (event) {
    event.preventDefault()
    this.$timerButton.hide()
    this.$timerProgress.show()
    this.explainPrepare.hide()
    this.explainOnTimer.show()

    this.startTime = new Date()
    this.interval = setInterval(this.handleInterval.bind(this), 250)
  }

  handleStartProcess (event) {
    this.videoBehavior.handleRecordStart()
    this.$recordingIndicator.addClass('active')
    event.preventDefault()
    this.activateStep(3)
  }

  handleEndProcess () {
    this.videoBehavior.handleRecordStop()
    this.$recordingIndicator.removeClass('active')
    this.activateStep(5)
  }

  handleInterval (event) {
    const currentTime = new Date()
    const seconds = Math.round((currentTime.getTime() - this.startTime.getTime()) / 1000)

    if (seconds > this.timerLengthSeconds) {
      clearInterval(this.interval)
      this.interval = null
      this.finalStep()
      return
    }

    const remainingTime = this.timerLengthSeconds - seconds
    const percent = 100 - (100 / this.timerLengthSeconds * remainingTime)
    this.$progessLabelValue.text(`${remainingTime}`)
    this.$timerProgressBar.width(`${percent}%`)
  }

  finalStep () {
    this.activateStep(4)
  }
}
