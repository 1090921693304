/* global Image */

import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import $ from 'jquery'
import { sizeFormatter, durationFormatter } from 'human-readable'
import VideoTake from '../videoTake'
import * as faceapi from '@vladmandic/face-api'

export default class video {
  constructor (element) {
    this.$element = $(element)
    this.$video = this.$element.find('video')
    this.$switchCam = this.$element.find('.switchCam')
    this.$recordStart = this.$element.find('.recordStart')
    this.$recordStop = this.$element.find('.recordStop')
    this.$video = this.$element.find('video')
    this.$canvas = this.$element.find('.faceDetection')

    this.$recordData = this.$element.find('.recordData')
    this.$videoLength = this.$element.find('.videoLength')
    this.$videoSize = this.$element.find('.videoSize')
    this.$videoBase64Size = this.$element.find('.videoBase64Size')
    this.$chunkCount = this.$element.find('.chunkCount')
    this.$detectedAge = this.$element.find('.detectedAge')
    this.$detectedGender = this.$element.find('.detectedGender')
    this.$detectedGenderProbability = this.$element.find('.detectedGenderProbability')
    this.$detectedEmotion = this.$element.find('.detectedEmotion')

    this.$videoPlayback = this.$element.find('.videoPlayback')

    this.cameraPhoto = null
    this.recordUrl = this.$element.data('recordUrl')

    this.$switchCam.on('click', this.handleSwitchCam.bind(this))

    this.$recordStart.on('click', this.handleRecordStart.bind(this))
    this.$recordStop.on('click', this.handleRecordStop.bind(this))

    this.allCams = []
    this.stream = null
    this.currentCamIdx = -1
    this.restartRecord = false

    this.takes = []

    this.init()

    this.faceApiInitialized = false
    this.optionsTinyFace = null
    this.faceDetectionRunning = false
    this.currentFaceData = this.getEmptyCurrentFaceData()

    this.initFaceApiTries = 0
    this.debugDrawings = false

    window.setInterval(this.updateUIInfo.bind(this), 1000)
  }

  setFaceDetect () {

  }

  init () {
    this.cameraPhoto = new CameraPhoto(this.$video.get(0))
    this.startCamera(FACING_MODES.USER)
  }

  startCamera (facing) {
    this.cameraPhoto.startCamera(facing, { width: 640, height: 480 })
      .then(this.handleStreamStart.bind(this))
      .catch(this.handleStreamError.bind(this))
  }

  handleStreamStart (stream) {
    this.initFaceApi()
      .then(results => {
        // console.log('initFaceApi done', results)
        this.updateUI()
        this.stream = stream
        this.initFaceApiTries = 0
        if (this.isRecordActive() || this.restartRecord) {
          this.restartRecord = false
          this.handleRecordStart()
        }
      })
      .catch(err => {
        // Catch any exception that's thrown and log this
        console.error('initFaceApi error')
        console.error(err)
        // something happened - retry in 1 second!
        this.initFaceApiTries++

        if (this.initFaceApiTries < 3) {
          window.setTimeout(() => {
            this.handleStreamStart(stream)
          }, 1000)
        }
      })
  }

  handleStreamError (err) {
    console.error(err)
  }

  getCurrentTake () {
    if (this.takes.length < 1) {
      return null
    }
    return this.takes[this.takes.length - 1]
  }

  stopAllTakes () {
    for (const take of this.takes) {
      take.stopRecording()
    }
  }

  isRecordActive () {
    for (const take of this.takes) {
      if (take.isRecording()) {
        return true
      }
    }
    return false
  }

  async getAllCams () {
    const cams = []
    await this.cameraPhoto.enumerateCameras()
      .then((cameras) => {
        cameras.forEach((camera) => {
          cams.push(camera.deviceId)
        })
      })
    return cams
  }

  async handleSwitchCam (event) {
    event.preventDefault()

    if (this.allCams.length < 1) {
      this.allCams = await this.getAllCams()
    }
    this.currentCamIdx++
    if (this.currentCamIdx > this.allCams.length - 1) {
      this.currentCamIdx = 0
    }

    if (this.isRecordActive()) {
      this.restartRecord = true
    }

    this.startCamera(this.allCams[this.currentCamIdx])
  }

  handleRecordStop (event) {
    if (event) {
      event.preventDefault()
    }
    this.stopAllTakes()
    this.updateUI()
  }

  handleRecordStart (event) {
    if (event) {
      event.preventDefault()
    }

    this.stopAllTakes()

    if (this.stream) {
      const take = new VideoTake(this.stream, this.takes.length, this.recordUrl, this.getCurrentFaceData.bind(this))
      this.takes.push(take)
      take.startRecord()

      const currentTake = this.getCurrentTake()
      if (currentTake) {
        currentTake.startRecord()
      }
    }

    this.updateUI()
  }

  updateUI () {
    const active = this.isRecordActive()
    this.$recordStart.toggle(!active)
    this.$recordStop.toggle(active)
    this.$videoPlayback.toggle(!active)
    this.updateUIInfo()
  }

  updateUIInfo () {
    let dataSize = 0
    let postedDataSize = 0
    let postedChunks = 0
    let duration = 0

    for (const take of this.takes) {
      const stats = take.getStats()
      dataSize += stats.dataSize
      postedDataSize += stats.postedDataSize
      postedChunks += stats.postedChunks
      duration += stats.duration
    }

    if (duration > 0) {
      this.$recordData.show()

      this.$chunkCount.text(postedChunks)

      const sizeFormat = sizeFormatter({
        std: 'JEDEC', // 'SI' (default) | 'IEC' | 'JEDEC'
        decimalPlaces: 2,
        keepTrailingZeroes: false,
        render: (literal, symbol) => `${literal} ${symbol}B`
      })
      this.$videoBase64Size.text(sizeFormat(postedDataSize))
      this.$videoSize.text(sizeFormat(dataSize))

      const durationFormat = durationFormatter({
        allowMultiples: ['m', 's'],
        keepNonLeadingZeroes: true // E.g. '1y 0mo 0d'
      })
      this.$videoLength.text(durationFormat(duration, { language: 'de' }))
    }

    this.$detectedAge.text(this.currentFaceData.age)
    this.$detectedGender.text(this.currentFaceData.gender)
    this.$detectedGenderProbability.text(Math.round(this.currentFaceData.genderProbability * 100))
    this.$detectedEmotion.each((index, item) => {
      const $emotion = $(item)
      const key = $emotion.data('emotion')
      const percentageValue = Math.round(this.currentFaceData.expression[key] * 100)
      $emotion.css('width', `${percentageValue}%`)
      // console.log($emotion.find('.detectedValue'))
      $emotion.find('.detectedValue').text(`${percentageValue}%`)
    })
  }

  getEmptyCurrentFaceData () {
    return {
      facePresent: false,
      age: null,
      gender: null,
      genderProbability: null,
      expression: {
        angry: null,
        disgusted: null,
        fearful: null,
        happy: null,
        neutral: null,
        sad: null,
        surprised: null
      },
      time: new Date().getTime()
    }
  }

  getCurrentFaceData () {
    return this.currentFaceData
  }

  async initFaceApi () {
    if (this.faceApiInitialized) {
      return
    }
    const modelPath = '/dist/model'
    await faceapi.nets.tinyFaceDetector.loadFromUri(modelPath)
    // await faceapi.nets.ssdMobilenetv1.loadFromUri(modelPath)
    await faceapi.nets.faceLandmark68Net.loadFromUri(modelPath)
    await faceapi.nets.ageGenderNet.loadFromUri(modelPath)
    // await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath)
    await faceapi.nets.faceExpressionNet.loadFromUri(modelPath)

    const imgSize = 320
    const minScore = 0.5
    this.optionsTinyFace = new faceapi.TinyFaceDetectorOptions({ inputSize: imgSize, scoreThreshold: minScore })
    const mediaSize = await this.getDimensionsOfStream()

    const ar = mediaSize.width / this.$video.width()

    mediaSize.width = mediaSize.width / ar
    mediaSize.height = mediaSize.height / ar

    const canvasTop = (mediaSize.height - this.$video.height()) / 2 * -1

    this.$canvas.css('top', canvasTop)
    this.$canvas.width(mediaSize.width)
    this.$canvas.height(mediaSize.height)

    this.faceApiInitialized = true

    await this.faceDetection()
      .then(results => {
        // console.log('faceDetection done', results)

        // do nothing
      })
      .catch(err => {
        // Catch any exception that's thrown and log this
        console.error('faceDetection err')
        console.error(err)
      })
  }

  async getDimensionsOfStream () {
    const dataUri = this.cameraPhoto.getDataUri({ size: 1, imageType: IMAGE_TYPES.JPG, imageCompression: 0.8 })

    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({
        width: img.width,
        height: img.height
      })
      img.onerror = reject
      img.src = dataUri
    })
  }

  async faceDetection () {
    if (!this.faceApiInitialized || this.faceDetectionRunning) {
      return
    }
    this.faceDetectionRunning = true

    let detections = null
    try {
      detections = await new Promise((resolve, reject) => {
        let failed = false
        let faceAPIPromise
        const now = Date.now() // Time now
        // We time out faceAPI in case it doesn't return within 250ms
        const timeout = setTimeout(() => {
          failed = true
          // Store the faceAPI promise to see if it ever resolves
          window.failedPromises = window.failedPromises || []
          window.failedPromises.push(faceAPIPromise)
          reject(new Error('Timed out'))
        }, 250)

        // Make the faceAPI call
        // The 'source' variable is a <video> element
        faceAPIPromise = faceapi
          .detectSingleFace(this.$video.get(0), this.optionsTinyFace)
          .withFaceLandmarks()
          .withFaceExpressions()
          .withAgeAndGender()
          .then(results => {
            if (failed) {
              // If failed is set, then we've timed out. Log this anyway
              console.warn(`Timed out call completed in: ${Date.now() - now}ms`)
            } else {
              // Success. Resolved within 250ms
              clearTimeout(timeout)
              resolve(results)
            }
          }).catch(err => {
            // Catch any exception that's thrown and log this
            console.warn(`faceAPI promise errored out in ${Date.now() - now}ms: ${err}`)
          }).then(() => {
            // If this promise is in the list of failed promises, then remove it since it resolved/rejected
            window.failedPromises = window.failedPromises || []
            const idx = window.failedPromises.indexOf(faceAPIPromise)
            if (idx > -1) {
              window.failedPromises.splice(idx, 1)
            }
          })
      })
    } catch (e) {
      console.warn(`Failed to get face landmarks within 250ms`)
    }

    // this.currentFaceData = this.getEmptyCurrentFaceData()
    const canvas = this.$canvas.get(0)
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)

    this.currentFaceData = this.getEmptyCurrentFaceData()

    if (detections) {
      this.currentFaceData.facePresent = true
      this.currentFaceData.age = Math.round((detections.age || 0) * 1000 / 1000)
      this.currentFaceData.gender = detections.gender || 'unkown'
      this.currentFaceData.genderProbability = Math.round((detections.genderProbability || 0) * 1000) / 1000
      for (const key in this.currentFaceData.expression) {
        this.currentFaceData.expression[key] = Math.round((detections.expressions[key] || 0) * 1000) / 1000
      }

      if (this.debugDrawings) {
        const dimensions = { width: this.$video.width(), height: this.$video.height() }
        // resize the detected boxes in case your displayed image has a different size then the original
        const detectionsForSize = faceapi.resizeResults(detections, dimensions)

        canvas.width = this.$video.width()
        canvas.height = this.$video.height()
        // draw them into a canvas
        faceapi.draw.drawDetections(canvas, detectionsForSize, { withScore: true })
        faceapi.draw.drawFaceLandmarks(canvas, detectionsForSize)
      }
    }
    this.setFaceDetect()

    this.faceDetectionRunning = false

    window.setTimeout(this.faceDetection.bind(this), 250)
  }
}
