import $ from 'jquery'
import Cleave from 'cleave.js'

export default class pageTestSample {
  constructor (body) {
    this.$body = $(body)
    this.$element = this.$body.find('section.mainContent')
    this.$codeInput = this.$element.find('input[name=barcode]')
    this.codeCleave = new Cleave(this.$codeInput.get(0), {
      delimiter: '-',
      blocks: [4, 4, 4],
      uppercase: true
    })
  }
}
