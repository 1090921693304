import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'

export default class confirmLink {
  constructor (element) {
    this.$element = $(element)
    this.$element.on('click', this.handleClick.bind(this))
    this.confirmUrl = this.$element.attr('href')
    this.modalId = this.$element.data('modalId')

    this.$modal = $(`#${this.modalId}`)
    this.$buttons = this.$modal.find('.modal-footer [type=button]')
  }

  handleClick (event) {
    event.preventDefault()

    this.modal = new Modal(this.$modal.get(0), {})
    this.$modal.one('hidden.bs.modal', () => {
      this.$buttons.off('click')
    })
    this.$buttons.one('click', this.handleConfirm.bind(this))
    this.modal.show()
  }

  handleConfirm (event) {
    const $button = $(event.target) // Button that triggered the modal
    const action = $button.data('action') // Extract info from data-* attributes
    if (action === 'confirm') {
      window.location.href = this.confirmUrl
    }
    this.modal.hide()
  }
}
