import $ from 'jquery'
import Swiper from 'swiper'

export default class pageTestSwabBreathwayTimer {
  constructor (body) {
    this.$body = $(body)
    this.$element = this.$body.find('section.mainContent')

    this.$timerButton = this.$element.find('.timerToolbar button')
    this.$timerStepsContainer = this.$element.find('.timerSteps')
    this.$timerProgress = this.$element.find('.timer .progress')
    this.$timerProgressBar = this.$element.find('.timer .progress-bar')
    this.$progessLabelValue = this.$element.find('.timer .progressLabelValue')

    this.$stageDiv = this.$element.find('.stage')
    this.$finishStep = this.$stageDiv.data('workflowAction')

    this.$workflowActionField = this.$element.find('input[name=workflowNavigation]')

    this.$timerButton.on('click', this.handleStartTimer.bind(this))

    this.interval = null
    this.startTime = null
    this.timerLengthSeconds = parseInt(this.$timerProgress.data('timerLength'), 10)

    this.swiper = new Swiper(this.$timerStepsContainer.get(0), {
      // allowSlideNext: false,
      allowSlidePrev: false,
      allowTouchMove: false
    })
    this.initialize()
  }

  activateStep (stepIdx) {
    this.swiper.slideTo(stepIdx - 1)
  }

  initialize () {
    this.activateStep(1)
  }

  handleStartTimer (event) {
    event.preventDefault()
    this.activateStep(2)
    this.startTime = new Date()
    this.interval = setInterval(this.handleInterval.bind(this), 250)
  }

  handleEndProcess () {
    this.$workflowActionField.val(this.$finishStep)
    const $form = this.$workflowActionField.parent('form')
    $form.trigger('submit')
  }

  handleInterval (event) {
    const currentTime = new Date()
    const seconds = Math.round((currentTime.getTime() - this.startTime.getTime()) / 1000)

    if (seconds > this.timerLengthSeconds) {
      clearInterval(this.interval)
      this.interval = null
      this.handleEndProcess()
      return
    }

    const remainingTime = this.timerLengthSeconds - seconds
    const percent = 100 - (100 / this.timerLengthSeconds * remainingTime)
    this.$progessLabelValue.text(`${remainingTime}`)
    this.$timerProgressBar.width(`${percent}%`)
  }
}
