import $ from 'jquery'

export default class submitFormButton {
  constructor (element) {
    this.$element = $(element)
    this.formId = this.$element.data('formId')
    this.$form = $(`form#${this.formId}`)
    this.$element.on('click', this.handleClick.bind(this))
  }

  handleClick (event) {
    event.preventDefault()
    // console.log('submit', this.$form)
    this.$form.trigger('submit')
  }
}
