import $ from 'jquery'

export default class pageIndex {
  constructor (body) {
    this.$body = $(body)
    this.$body.one('click', this.handleClick.bind(this))
    $(window.document).one('scroll', this.handleScroll.bind(this))
  }

  handleScroll (event) {
    this.$body.removeClass('intro')
  }

  handleClick (event) {
    this.$body.removeClass('intro')
  }
}
